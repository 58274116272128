import { gql } from '@apollo/client'
import EventEmitter from 'events'
import localForage from 'localforage'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { DATES_KEY } from '../../constants/dates'
import { GAMES_PUBLISHED } from '../../constants/games'
import { VENUE_KEY } from '../../constants/venue'
import {
  AllGamesQuery,
  AllGamesVariables,
  GameFragmentFragment,
  GameFragmentFragmentDoc,
  VenueVenue,
} from '../../generated/graphql'
import useFeatureFlagOn from '../../hooks/useFeatureFlagOn'
import { DBUpdate, DBWrite } from '../../hooks/useGameDb'
import { useSessionLazyQuery } from '../../hooks/useSession'
import { ScoringRoles } from '../../providers/LiveScoringProvider/LiveScoringProvider.types'
import { excludeFields } from '../../utils/graphql'
import AuthContext from '../AuthContext'
import {
  gameResetEvent,
  gameUpdatedInPlaymakerEvent,
  liveGameConnectionStateEvent,
  liveGameOwnerNotificationEvent,
  locallyScoredGameUpdateEvent,
  publishedGameUpdateEvent,
} from '../GameSyncing/GameSyncing.types'
import {
  IdbSessionDatastore,
  IdbSessionReadonlyDatastore,
} from '../GameSyncing/idb/idbSessionDatastore'
import { useSyncingWorker } from '../GameSyncing/useSyncingWorker'
import { SessionContext } from '../SessionContext/SessionContext'
import { GameResultPublishedGame } from './GameDatastoreContext.types'

export const GAMES = gql`
  query allGames($id: ID!, $dates: [ISODate!]!) {
    venue(id: $id) {
      id
      courts {
        id
        games(filter: $dates) {
          ...GameFragment
        }
      }
    }
  }
  ${GameFragmentFragmentDoc}
`

export type PublishedGameStatus =
  | 'NOT_PUBLISHED'
  | 'PUBLISHING'
  | 'PUBLISHED'
  | 'PUBLISHED_OUTSIDE'
  | 'ERROR'
  | 'WAITING_ON_LIVE_SCORING'

export interface GameTypePublishedGame {
  dismissalsPerBatter?: number
  value?: string
}

export interface GameToPublish {
  id: string
  status: PublishedGameStatus
  retryCount?: number
  error?: string
  result?: GameResultPublishedGame
  gameType?: GameTypePublishedGame
}

export interface GamePutPayload {
  gameID: string
  data: Array<DBWrite | DBUpdate>
}

/**
 * Context which handles storage, retrieval and managing of the event log and also the
 * finalised games scores on a device.
 */
export const GameDatastoreContext = React.createContext<{
  clearDatabases: () => Promise<void>
  addGames: (ids: string[]) => void
  scoreGame: (id: string) => void
  changeScoringRole: (gameID: string, role: ScoringRoles) => void
  updatedGame: (id: string, date: Date) => void
  sessionDatastore: IdbSessionReadonlyDatastore | undefined
  queuePut: (payload: GamePutPayload, onComplete: () => void) => void
  addGameToPublish: ({
    id,
    result,
    gameType,
  }: {
    id: string
    result: GameResultPublishedGame
    gameType?: GameTypePublishedGame
  }) => void
  eventEmitter: EventEmitter
}>({
  clearDatabases: () => Promise.resolve(),
  scoreGame: () => null,
  changeScoringRole: () => null,
  updatedGame: () => null,
  addGames: () => null,
  sessionDatastore: undefined,
  queuePut: () => Promise.resolve(),
  addGameToPublish: () => Promise.resolve(undefined),
  eventEmitter: new EventEmitter(),
})

export const Provider: React.FC = ({ ...props }) => {
  const { sessionAuth } = useContext(AuthContext)
  const { getSessionID } = useContext(SessionContext)
  const {
    initWorker,
    sendEvent,
    scoreGame,
    publishGame,
    changeScoringRole,
    cleanupWorker,
    updatedGame,
  } = useSyncingWorker({
    emitEvents: p => {
      eventEmitter.current.emit('events', p)
    },
    emitLiveGameConnectionState: p => {
      eventEmitter.current.emit(liveGameConnectionStateEvent, p)
    },
    emitLiveGameOwnerNotification: p => {
      eventEmitter.current.emit(liveGameOwnerNotificationEvent, p)
    },
    emitLocallyScoreGameUpdate: p => {
      eventEmitter.current.emit(locallyScoredGameUpdateEvent, p)
    },
    emitPublishedGameUpdate: p => {
      eventEmitter.current.emit(publishedGameUpdateEvent, p)
    },
    emitPlaymakerGameUpdate: p => {
      eventEmitter.current.emit(gameUpdatedInPlaymakerEvent, p)
    },
    emitGameResetNotification: p => {
      eventEmitter.current.emit(gameResetEvent, p)
    },
  })
  const eventEmitter = useRef<EventEmitter>(new EventEmitter())
  const sessionDB = useRef<IdbSessionDatastore | undefined>()

  const isLiveStreamingEnabled = useFeatureFlagOn('live-streaming-enabled')

  // Need this to be a memo, otherwise we end up tied to the render loop and the query will execute infinitely
  const allGamesDocument = useMemo(
    () =>
      excludeFields(GAMES, [
        {
          key: 'liveStreamingEnabled',
          include: isLiveStreamingEnabled,
        },
      ]),
    [isLiveStreamingEnabled],
  )

  const [getGames, { data }] = useSessionLazyQuery<
    AllGamesQuery,
    AllGamesVariables
  >(allGamesDocument, {
    fetchPolicy: 'cache-only',
  })

  const games = useMemo(
    () =>
      data?.venue?.courts?.reduce<GameFragmentFragment[]>(
        (acc, curr) => (curr.games ? [...acc, ...curr.games] : acc),
        [],
      ) || [],
    [data],
  )

  const workerInit = useCallback(async () => {
    let userInfo: any | undefined = undefined
    // If we're in the process of logging out, this can trigger.
    try {
      userInfo = await sessionAuth.currentAuthenticatedUser()
    } catch (e) {
      return
    }

    const venue = await localForage.getItem<VenueVenue>(VENUE_KEY)
    if (!venue?.id) {
      throw new Error('no venue found when attempting to init worker')
    }

    const sessionID = getSessionID()
    if (!sessionID) {
      throw new Error('no session found when attempting to init worker')
    }

    if (!sessionDB.current) {
      sessionDB.current = new IdbSessionDatastore(() => {})
    }

    initWorker({
      games: games.map(g => g.id),
      venueID: venue.id,
      websocketURL: process.env.REACT_APP_TIP_OFF_WS_ENDPOINT,
      authInfo: {
        sessionID,
        idToken: userInfo.signInUserSession.idToken.jwtToken,
        lastAuthUser: userInfo.username,
        refreshToken: userInfo.signInUserSession.refreshToken.token,
        keyPrefix: userInfo.keyPrefix,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games, initWorker, sessionAuth])

  const addGames = async (_: string[]) => {
    await fetchGames()
  }

  const clearDatabases = async () => {
    await localForage.setItem(GAMES_PUBLISHED, [])

    await sessionDB.current?.closeDatabase()
    cleanupWorker()
  }

  useEffect(() => {
    sessionDB.current = new IdbSessionDatastore(() => {})
    fetchGames()

    return () => {
      sessionDB.current?.closeDatabase()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchGames = async () => {
    const dates = await localForage.getItem<string[] | null>(DATES_KEY)
    const venue = await localForage.getItem<VenueVenue | null>(VENUE_KEY)

    if (venue && dates && dates.length > 0) {
      getGames({
        variables: {
          id: venue.id,
          dates,
        },
      })
    }
  }

  useEffect(() => {
    if (games.length > 0) {
      workerInit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [games])

  const addGameToPublish = ({
    id,
    result,
  }: {
    id: string
    result: GameResultPublishedGame
  }): void => {
    return publishGame({
      id,
      status: 'NOT_PUBLISHED',
      result,
    })
  }

  return (
    <GameDatastoreContext.Provider
      value={{
        addGames,
        sessionDatastore: sessionDB.current,
        clearDatabases,
        addGameToPublish,
        queuePut: sendEvent,
        scoreGame,
        changeScoringRole,
        updatedGame,
        eventEmitter: eventEmitter.current,
      }}
    >
      {props.children}
    </GameDatastoreContext.Provider>
  )
}

export const Consumer = GameDatastoreContext.Consumer

const gameDatastoreContext = {
  Provider,
  Consumer,
}

export default gameDatastoreContext
