import { useCallback, useContext, useEffect, useState } from 'react'
import { GameDatastoreContext } from '../components/GameDatastoreContext/GameDatastoreContext'
import {
  GameSyncingWorkerGameResetEvent,
  GameSyncingWorkerLiveGameConnectionState,
  GameSyncingWorkerLiveGameOwnerNotification,
  gameResetEvent,
  liveGameConnectionStateEvent,
  liveGameOwnerNotificationEvent,
} from '../components/GameSyncing/GameSyncing.types'
import { LiveScoringContext } from '../providers/LiveScoringProvider'
import {
  GameScoringStatus,
  ScoringRoles,
} from '../providers/LiveScoringProvider/LiveScoringProvider.types'

export interface UseLiveGameProps {
  gameID?: string
  onGameOwnerChange?: (
    e: GameSyncingWorkerLiveGameOwnerNotification['payload'],
  ) => void
  onConnectionStateChange?: (
    e: GameSyncingWorkerLiveGameConnectionState['payload'],
  ) => void
  onGameReset?: () => void
}

export interface UseLiveGameReturn {
  scoreGame: (gameID: string) => void
  changeScoringRole: (gameID: string, role: ScoringRoles) => void
  scoringRole?: ScoringRoles
  isLive: boolean
}

/**
 * Hook to expose functionality around a live game, for example
 * Scoring, role changes live status
 *
 */
export const useLiveGame = (props: UseLiveGameProps): UseLiveGameReturn => {
  const { scoreGame, eventEmitter, changeScoringRole, sessionDatastore } =
    useContext(GameDatastoreContext)
  const { isLive: isLiveDeprecated } = useContext(LiveScoringContext)

  const [isLive, setLive] = useState<boolean>(false)
  const [scoringRole, setScoringRole] = useState<ScoringRoles | undefined>(
    undefined,
  )

  const setRoleAndLiveState = useCallback((status?: GameScoringStatus) => {
    switch (status) {
      case GameScoringStatus.CanScore:
        setScoringRole('PRIMARY')
        break
      case GameScoringStatus.CanScoreSecondary:
        setLive(false) // Cant be live with secondary scorer
        setScoringRole('SECONDARY')
        break
      case GameScoringStatus.NotOwner:
      case GameScoringStatus.AdminOwner:
      case GameScoringStatus.InvalidStatus:
        setLive(false) // Can't be live for these three statuses
        setScoringRole(undefined)
    }
  }, [])

  useEffect(() => {
    const onGameOwnerChangeCallback = (
      e: GameSyncingWorkerLiveGameOwnerNotification['payload'],
    ) => {
      if (e.gameID === props?.gameID) {
        setRoleAndLiveState(e.status)

        if (props.onGameOwnerChange) {
          props.onGameOwnerChange(e)
        }
      }
    }

    const onConnectionStateChangeCallback = (
      e: GameSyncingWorkerLiveGameConnectionState['payload'],
    ) => {
      setLive(scoringRole === 'PRIMARY' && e.connected)

      if (props.onConnectionStateChange) {
        props.onConnectionStateChange(e)
      }
    }

    const onGameResetCallback = (
      e: GameSyncingWorkerGameResetEvent['payload'],
    ) => {
      if (e.gameID === props?.gameID) {
        setLive(false)
        setScoringRole(undefined)

        if (props.onGameReset) {
          props.onGameReset()
        }
      }
    }

    eventEmitter.on(
      liveGameConnectionStateEvent,
      onConnectionStateChangeCallback,
    )
    eventEmitter.on(liveGameOwnerNotificationEvent, onGameOwnerChangeCallback)

    eventEmitter.on(gameResetEvent, onGameResetCallback)

    return () => {
      eventEmitter.removeListener(
        liveGameOwnerNotificationEvent,
        onGameOwnerChangeCallback,
      )

      eventEmitter.removeListener(
        liveGameConnectionStateEvent,
        onConnectionStateChangeCallback,
      )

      eventEmitter.removeListener(gameResetEvent, onGameResetCallback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventEmitter, isLive, scoringRole, props])

  useEffect(() => {
    const loadInitialState = async () => {
      if (props.gameID) {
        const game = await sessionDatastore?.getLocallyScoredGame(props.gameID)
        if (props.onGameOwnerChange && game && game.lastGameScoringStatus) {
          props.onGameOwnerChange({
            gameID: props.gameID,
            currentScoringRole: game.scoringRole,
            status: game.lastGameScoringStatus,
          })
        }

        setRoleAndLiveState(game?.lastGameScoringStatus)
      }
    }
    loadInitialState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    scoreGame,
    changeScoringRole,
    scoringRole,
    isLive: isLive || isLiveDeprecated,
  }
}
