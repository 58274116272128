import { GameEvents } from '@gameonsports/play-by-play'
import { SyncedGameEvents } from '../../hooks/useGameDb'

export enum LiveScoringState {
  Idle = 'IDLE',
  Scoring = 'SCORING',
  GameAlreadyOwned = 'GAME_ALREADY_OWNED',
  AdminOverridden = 'ADMIN_OVERRIDDEN',
  InvalidStatus = 'GAME_INVALID_STATUS',
  SyncInProgress = 'SYNC_IN_PROGRESS',
  SyncComplete = 'SYNC_COMPLETE',
  GameTakeoverRequest = 'GAME_TAKEOVER_REQUEST',
}

export enum LiveScoringCloseCode {
  GameAlreadyOwned = 4000,
  GameIncorrectStatus = 4001,
  AdminOverridden = 4002,
  GameTakeover = 4003,
}

export enum SyncStatus {
  AlreadyRecorded = 'ALREADY_RECORDED',
  Failed = 'FAILED',
  NotSynced = 'NOT_SYNCED',
  Succeeded = 'SUCCEEDED',
}

export enum LiveScoringEventType {
  ScoringEventsProcess = 'SCORING_EVENTS_PROCESS',
  ClientModeUpdate = 'CLIENT_MODE_UPDATE',
  GameTakeover = 'GAME_TAKEOVER',
  GameEventLog = 'GAME_EVENT_LOG',
  GameUpdated = 'GAME_UPDATED',
  GameVersions = 'GAME_VERSIONS',
  Hello = 'HELLO',
  KeepAlive = 'KA',
  ClearOwner = 'CLEAR_OWNER',
  Identify = 'IDENTIFY',
}

export enum LiveScoringEventResponseStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED',
}

export enum GameScoringStatus {
  CanScore = 'CAN_SCORE',
  CanScoreSecondary = 'CAN_SCORE_SECONDARY',
  NotOwner = 'CANT_SCORE_NOT_OWNER',
  AdminOwner = 'CANT_SCORE_ADMIN_OWNER',
  InvalidStatus = 'CANT_SCORE_INVALID_GAME_STATUS',
}

export type ScoringRoles = 'PRIMARY' | 'SECONDARY'

export interface ScoringEventsRequest {
  type: LiveScoringEventType.ScoringEventsProcess
  payload: {
    gameID: string
    events: SyncedGameEvents[]
  }
}

export interface ScoringEventsResponse {
  type: LiveScoringEventType.ScoringEventsProcess
  status: LiveScoringEventResponseStatus
  payload: {
    gameID: string
    events: Array<{ id: string; status: SyncStatus }>
  }
}

export interface ClientModeUpdateRequest {
  type: LiveScoringEventType.ClientModeUpdate
  payload: {
    gameID: string
    mode: 'SCORING'
    benchVersion: string
  }
}

export interface ClientModeUpdateResponse {
  type: LiveScoringEventType.ClientModeUpdate
  status: LiveScoringEventResponseStatus
  payload: {
    gameID: string
    lastEventRecordedTime?: number
    lastEventRecordedID?: string
    scoringStatus: GameScoringStatus
  }
}

export interface GameTakeoverRequest {
  type: LiveScoringEventType.GameTakeover
  payload: {
    gameID: string
  }
}

export interface GameTakeoverResponse {
  type: LiveScoringEventType.GameTakeover
  status: LiveScoringEventResponseStatus
  payload: {
    gameID: string
  }
}

export interface GameEventLogRequest {
  type: LiveScoringEventType.GameEventLog
  payload: {
    gameID: string
  }
}

export interface GameEventLogResponse {
  type: LiveScoringEventType.GameEventLog
  status: LiveScoringEventResponseStatus
  payload: {
    gameID: string
    events: GameEvents[]
  }
}

export interface HelloMessage {
  type: LiveScoringEventType.Hello
  status: LiveScoringEventResponseStatus
  payload: {
    authenticationRequired: boolean
  }
}

export interface KeepAliveMessage {
  type: LiveScoringEventType.KeepAlive
}

export interface ClearOwnerRequest {
  type: LiveScoringEventType.ClearOwner
  payload: {
    gameID: string
  }
}

export interface IdentifyMessage {
  type: LiveScoringEventType.Identify
  payload: {
    token: string
    gameIDs: Readonly<string[]>
    venueID: string
  }
}

export interface IdentifyResponse {
  type: LiveScoringEventType.Identify
  status: LiveScoringEventResponseStatus
  payload: {
    authenticationSuccessful: boolean
    // These are returned but not needed
    // gameIDs: Readonly<string[]>
    // venueID: string
  }
}

export interface GameUpdatedMessage {
  type: LiveScoringEventType.GameUpdated
  status: LiveScoringEventResponseStatus
  payload: {
    gameID: string
    updatedAt?: string
    scoringStatus?: GameScoringStatus
  }
}

export interface GameVersionsMessage {
  type: LiveScoringEventType.GameVersions
  status: LiveScoringEventResponseStatus
  payload: {
    versions: Array<{
      gameID: string
      updatedAt: string
    }>
  }
}

export type LiveScoringEventResponseTypes =
  | ScoringEventsResponse
  | ClientModeUpdateResponse
  | GameTakeoverResponse
  | GameEventLogResponse
  | HelloMessage
  | GameUpdatedMessage
  | GameVersionsMessage
  | IdentifyResponse

export type LiveScoringGameSpecificEventResponseTypes =
  | ScoringEventsResponse
  | ClientModeUpdateResponse
  | GameTakeoverResponse
  | GameEventLogResponse
  | GameUpdatedMessage

export type LiveScoringEventRequestTypes =
  | ScoringEventsRequest
  | ClientModeUpdateRequest
  | GameTakeoverRequest
  | GameEventLogRequest
  | IdentifyMessage
  | ClearOwnerRequest

export interface Sync {
  status: SyncStatus
  syncAttempts: number
  syncedAt?: number
}

export interface SyncEvent {
  sync?: Sync
}
