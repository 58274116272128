import { useTreatments } from '@splitsoftware/splitio-react'
import { Attributes } from '@splitsoftware/splitio/types/splitio'
import localForage from 'localforage'
import merge from 'lodash/merge'
import { useEffect, useState } from 'react'
import { GAME_COUNT, VENUE_KEY } from '../constants/venue'
import { FeatureFlag } from '../flags'
import { VenueVenue } from '../generated/graphql'
import useMemoizedEqualObject from './useMemoizedEqualObject'

const useFeatureFlag = (name: FeatureFlag, attributes: Attributes = {}) => {
  const [venueID, setVenueID] = useState<string | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const venue = await localForage.getItem<VenueVenue>(VENUE_KEY)
      setVenueID(venue?.id)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const memoizedData = useMemoizedEqualObject(
    merge({}, attributes, {
      tenant: process.env.REACT_APP_TENANT_SLUG,
      venueID: venueID,
      gameCount: localStorage.getItem(GAME_COUNT) ?? '0',
    }),
  )
  const treatments = useTreatments([name], memoizedData)
  const treatmentWithConfig = treatments[name]
  return [treatmentWithConfig.treatment, treatmentWithConfig.config]
}

export default useFeatureFlag
