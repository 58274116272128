import { mq } from '@gameonsports/components/lib/_utils/styled-components-utils'
import { Text } from '@gameonsports/components/lib/TextV3'
import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import Modal from '../Modal'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -40%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
`

export const DialogHeading = styled(Text).attrs({
  size: '24',
  weight: '700',
})`
  margin-bottom: 1rem;
`

const StyledModal = styled(Modal)<typeof Modal & { animationEnd?: boolean }>`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  padding: 2rem 2rem 3rem;
  width: calc(100% - 2rem);
  max-width: 27rem;
  animation: ${fadeIn} 500ms ease 100ms;
  opacity: ${props => (props.animationEnd ? '1' : '0')};

  ${mq.up('tablet')} {
    height: auto;
    top: 50%;
  }
`

const DialogModal: typeof Modal = props => {
  const [animationEnd, setAnimationEnd] = useState(false)

  // iOS devices get confused when animating transform immediately after a node has been added to the DOM.
  // So, we add a delay of 100ms to the animation and set the opacity of the modal as 0 to begin with, and
  // then as 1 when the animation is almost done to ensure the modal stays visible.
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationEnd(true)
    }, 500)
    return () => clearTimeout(timer)
  }, [])

  return <StyledModal {...props} animationEnd={animationEnd} />
}

export default DialogModal
