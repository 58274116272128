import localForage from 'localforage'
import React, { useContext, useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import AuthContext from '../../components/AuthContext'
import { LOCK_KEY } from '../../constants/lock'
import { LOCAL_STORAGE_SESSION_ID_KEY } from '../../constants/session'

export const SessionContext = React.createContext<{
  sessionID?: string
  lock?: string
  setLock: (lock: string | undefined) => void
  lastUpdated?: Date
  setLastUpdated: (time: Date) => void
  createNewSessionID: () => void
  clearSessionID: () => void
  getSessionID: () => string | undefined
  email?: string
}>({
  sessionID: localStorage.getItem(LOCAL_STORAGE_SESSION_ID_KEY) ?? undefined,
  lock: undefined,
  setLock: () => null,
  lastUpdated: undefined,
  setLastUpdated: () => null,
  createNewSessionID: () => null,
  clearSessionID: () => null,
  getSessionID: () =>
    localStorage.getItem(LOCAL_STORAGE_SESSION_ID_KEY) ?? undefined,
  email: undefined,
})

export const Provider: React.FC = props => {
  const { defaultAuth, sessionAuth } = useContext(AuthContext)

  const [lock, setLockState] = useState<string | undefined>(undefined)
  const [lastUpdated, setLastUpdatedState] = useState<Date | undefined>(
    undefined,
  )
  const [sessionID, setSessionID] = useState<string | undefined>(
    localStorage.getItem(LOCAL_STORAGE_SESSION_ID_KEY) ?? undefined,
  )

  useEffect(() => {
    ;(async () => {
      const storedLock = await localForage.getItem<string>(LOCK_KEY)

      if (storedLock) {
        setLockState(storedLock)
      }
    })()
  }, [defaultAuth, sessionAuth])

  const setLock = (updatedLock: string | undefined) => {
    localForage.setItem(LOCK_KEY, updatedLock)
    setLockState(updatedLock)

    return updatedLock
  }

  const setLastUpdated = (updatedTime: Date) => {
    setLastUpdatedState(updatedTime)

    return updatedTime
  }

  const createNewSessionID = () => {
    const id = uuid()
    localStorage.setItem(LOCAL_STORAGE_SESSION_ID_KEY, id)
    setSessionID(id)
  }

  const clearSessionID = () => {
    localStorage.removeItem(LOCAL_STORAGE_SESSION_ID_KEY)
    setSessionID(undefined)
  }

  return (
    <SessionContext.Provider
      value={{
        lock,
        setLock,
        lastUpdated,
        setLastUpdated,
        sessionID,
        createNewSessionID,
        clearSessionID,
        getSessionID: () =>
          localStorage.getItem(LOCAL_STORAGE_SESSION_ID_KEY) ?? undefined,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  )
}

export const Consumer = SessionContext.Consumer

export default SessionContext
