import { SplitFactory } from '@splitsoftware/splitio-react'
import React from 'react'
import { FeatureFlag, featureFlags } from '../../flags'

const SDK_CONFIG_OBJECT = {
  core: {
    authorizationKey:
      process.env.REACT_APP_SPLIT_IO_MOCKS === 'true'
        ? 'localhost'
        : String(process.env.REACT_APP_SPLITIO_API_KEY),
    key: 'default',
    trafficType: 'A_TRAFFIC_TYPE',
  },
  scheduler: {
    featuresRefreshRate: 60,
  },
  storage: {
    type: 'LOCALSTORAGE' as 'LOCALSTORAGE',
  },
  debug: process.env.REACT_APP_FEATURE_FLAG_DEBUG === 'true',
  features:
    process.env.REACT_APP_SPLIT_IO_MOCKS === 'true'
      ? ({
          ...Object.fromEntries(
            Object.keys(featureFlags).map(ff => [ff, 'on']),
          ),
          // Override feature flag local mocks
          // featureFlagName: 'off',
          'kill-login': 'off',
          'bench-syncing-logging': 'off',
          'live-streaming-enabled': 'off',
          'game-payments': 'off',
        } as Record<FeatureFlag, 'on' | 'off'>)
      : undefined,
}

const FeatureFlagProvider: React.FC = ({ children }) => {
  return (
    <SplitFactory config={SDK_CONFIG_OBJECT}>
      {children as JSX.Element}
    </SplitFactory>
  )
}

export default FeatureFlagProvider
