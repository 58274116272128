import React from 'react'

interface BodyContextValues {
  overflowHidden: boolean
  toggleOverflowHidden: React.Dispatch<React.SetStateAction<void>>
}

const BodyContext = React.createContext<BodyContextValues>({
  overflowHidden: false,
  toggleOverflowHidden: () => null,
})

export default BodyContext
