import { Attributes } from '@splitsoftware/splitio/types/splitio'
import { FeatureFlag } from '../flags'
import useFeatureFlag from './useFeatureFlag'

const useFeatureFlagOn = (name: FeatureFlag, attributes: Attributes = {}) => {
  const [treatment] = useFeatureFlag(name, attributes)
  return treatment === 'on'
}

export default useFeatureFlagOn
