import { FaroErrorBoundary } from '@grafana/faro-react'
import React from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'

interface ErrorBoundaryProps {
  children: React.ReactNode
  fallback: React.ReactElement // both components accept ReactElement for this prop
}

/**
 * Returns a grafana FaroErrorBoundary if faro is initialized, or falls back
 * to a generic react error boundary to handle uncaught errors. Most environments
 * will have faro, but for any that do not, or if the app is running locally or
 * in the test environment, the generic fallback will be used. This is because
 * the faro boundary does not have any internal logic to check if faro has been
 * initialized, so it will throw errors trying to access things that dont exist.
 */
export const ErrorBoundary = ({ fallback, children }: ErrorBoundaryProps) => {
  if (process.env.REACT_APP_GRAFANA_FARO_URL) {
    return <FaroErrorBoundary fallback={fallback}>{children}</FaroErrorBoundary>
  }

  return <ReactErrorBoundary fallback={fallback}>{children}</ReactErrorBoundary>
}
