/**
 * Returns boolean value of true if location pathname matches router path string prop
 */
export const matchPath = (locationPathname: string, routerPath: string) => {
  const hasWildcard = routerPath.slice(-1) === '*'
  const regex = new RegExp(
    '^' + routerPath.replace(/:[^/]+/g, '[^/]+') + (hasWildcard ? '' : '$'),
  )
  return regex.test(locationPathname)
}
