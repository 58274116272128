import { mq } from '@gameonsports/components/lib/_utils/styled-components-utils'
import { Box } from '@gameonsports/components/lib/Box'
import Icon from '@gameonsports/components/lib/Icon'
import React, { useContext, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { HEADER_HEIGHT } from '../../constants/ui'
import { useInnerWidthHeight } from '../../hooks/useInnerWidthHeight'
import { PopoverContext } from '../../providers/PopoverProvider/PopoverProvider'
import BodyContext from '../BodyContext'

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

type ModalWidth = 'half' | 'two-thirds'

interface ModalBodyProps {
  height: number
  width: ModalWidth
  animate: boolean
}

const ModalBody = styled.div<ModalBodyProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${props => props.theme.white400};
  animation-name: ${props => (props.animate ? fadeIn : '')};
  animation-duration: 500ms;
  animation-timing-function: ease;
  width: 100%;
  overflow: auto;
  ${props =>
    css`
      height: ${props.height}px;
    `}

  ${mq.up('mobile')} {
    top: ${HEADER_HEIGHT};
    width: ${(props: ModalBodyProps) =>
      props.width === 'half' ? '80%' : '95%'};
    height: ${props => `calc(${props.height}px - ${HEADER_HEIGHT})`};
  }
  ${mq.up('tablet')} {
    width: ${(props: ModalBodyProps) =>
      props.width === 'half' ? '50%' : '95%'};
  }

  ${mq.up('desktop')} {
    width: ${(props: ModalBodyProps) =>
      props.width === 'half' ? '50%' : '66.66%'};
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  padding: 2rem 1rem;
  cursor: pointer;
  z-index: 1;
  outline: none;

  &:hover,
  &:focus,
  &:active {
    svg {
      fill: ${props => props.theme.blueberry400};
    }
  }

  ${mq.up('tablet')} {
    padding: 2rem;
  }
`

const CloseIcon = styled(Icon)`
  transition: fill 150ms ease;
`

export const ModalContent: React.FC = ({ children }) => (
  <Box
    paddingX={['m', 'xl']}
    flexGrow="1"
    display="flex"
    flexDirection="column"
  >
    {children}
  </Box>
)

interface ModalProps {
  hasCloseButton?: boolean
  onClickOutside?: () => void
  className?: string
  children: React.ReactNode
  width?: ModalWidth
  animate?: boolean
  'aria-label'?: string
}

const Modal: React.FC<ModalProps> = ({
  className,
  children,
  hasCloseButton,
  onClickOutside,
  width = 'half',
  animate,
  ...props
}) => {
  const { overflowHidden, toggleOverflowHidden } = useContext(BodyContext)
  const { popover } = useContext(PopoverContext)
  const { height } = useInnerWidthHeight()
  const modalRef = React.createRef<HTMLDivElement>()

  const closeModalOnClickOutside = (
    event: MouseEvent,
    modal: React.RefObject<HTMLDivElement>,
  ) => {
    if (
      modal &&
      modal.current &&
      !modal.current.contains(event.target as Node) &&
      !popover &&
      onClickOutside
    ) {
      onClickOutside()
    }
  }

  const handleClick = (e: any) => {
    closeModalOnClickOutside(e, modalRef)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false)
    return () => document.removeEventListener('mousedown', handleClick, false)
  })

  useEffect(() => {
    if (!overflowHidden) {
      toggleOverflowHidden()
    }

    return () => {
      if (overflowHidden) {
        toggleOverflowHidden()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overflowHidden])

  return (
    <ModalContainer
      role="dialog"
      aria-modal="true"
      aria-label={props['aria-label']}
    >
      <ModalBody
        className={className}
        ref={modalRef}
        height={height}
        width={width}
        animate={animate ?? true}
      >
        {hasCloseButton && (
          <CloseButton
            data-testid="modal-close-button"
            title="Close Modal"
            onClick={onClickOutside}
          >
            <CloseIcon name="cross" color="blueberry400" size="12" />
          </CloseButton>
        )}
        {children}
      </ModalBody>
    </ModalContainer>
  )
}

export default Modal
