import theme, { colors } from '@gameonsports/components/lib/theme'

// TODO: Move to react-components as 'Scoring' theme variant. This file can
// then be removed, and the theme can directly import where its needed like
// `import { ScoringTheme } from '@gameonsports/components` etc.
const benchThemeOverride = {
  primary: colors.blueberry400,
  buttonPrimary: colors.blueberry400,
}

const eScoringTheme = {
  ...theme,
  ...benchThemeOverride,
}

export default eScoringTheme
