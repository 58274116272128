import React, { useEffect } from 'react'

const useOffline = (): boolean => {
  const [offline, setOffline] = React.useState(!navigator.onLine)

  useEffect(() => {
    const online = () => setOffline(false)
    const offline = () => setOffline(true)

    window.addEventListener('online', online)
    window.addEventListener('offline', offline)

    return () => {
      window.removeEventListener('online', online)
      window.removeEventListener('offline', offline)
    }
  }, [])

  return offline
}

export default useOffline
