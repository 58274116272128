import React, { useEffect } from 'react'

export const useInnerWidthHeight = (): {
  height: number
  width: number
} => {
  const [widthHeight, setWidthHeight] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })
  useEffect(() => {
    let timeout: NodeJS.Timeout
    const resize = () => {
      timeout = setTimeout(
        () =>
          setWidthHeight({
            width: window.innerWidth,
            height: window.innerHeight,
          }),
        100,
      )
    }
    // iOS triggers window resize events when the action bar is show, when a toolbar is shown after focussing on an
    // input field, and when the address bar minimises in size. Unfortunately, all these resize events have animations.
    // This means that when the resize event is triggered, the animation hasn't necessarily finished. So the window
    // dimensions aren't correct. So, we have to wait for the animation to finish (~100ms) until we set the window dimensions.
    window.addEventListener('resize', resize)

    return () => {
      clearTimeout(timeout)
      window.removeEventListener('resize', resize)
    }
  }, [])
  return widthHeight
}

export default useInnerWidthHeight
