import { mq } from '@gameonsports/components/lib/_utils/styled-components-utils'
import React from 'react'
import styled from 'styled-components'
import NoMarginText from '../NoMarginText'

const Panel = styled.div`
  display: grid;
  grid-gap: 1rem;
  text-align: center;
  align-self: flex-start;
  margin: 2rem;
  padding: 2.5rem 1.5rem;
  background: ${props => props.theme.white400};
  font-family: Karla, sans-serif;
`

const Title = styled(NoMarginText)`
  font-size: 2.5rem;

  ${mq.up('tablet')} {
    font-size: 3rem;
  }
`

interface ErrorPanelProps {
  title: string
  description: string
  'data-testid'?: string
}

const ErrorPanel: React.FC<ErrorPanelProps> = ({
  title,
  description,
  ...props
}) => (
  <Panel data-testid={props['data-testid']}>
    <Title as="h1" weight="700">
      {title}
    </Title>
    <NoMarginText as="p" size="18" fontStyle="italic">
      {description}
    </NoMarginText>
  </Panel>
)

export default ErrorPanel
