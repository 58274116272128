import { Auth } from '@aws-amplify/auth'
import { Auth as AuthSession } from '@aws-amplify/auth-session'
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth'
import React from 'react'

Auth.configure({
  userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
})

export const defaultAuth = Auth

AuthSession.configure({
  userPoolWebClientId:
    process.env.REACT_APP_AMPLIFY_BENCH_USER_POOL_WEB_CLIENT_ID,
  userPoolId: process.env.REACT_APP_AMPLIFY_BENCH_USER_POOL_ID,
})

export const sessionAuth = AuthSession

export const getBearerToken = () =>
  sessionAuth
    .currentSession()
    .then(session => {
      const accessToken = session.getIdToken()
      return accessToken.getJwtToken()
    })
    .catch(e => {
      console.error(e)
      return undefined
    })

const AuthContext = React.createContext<{
  defaultAuth: AuthClass
  sessionAuth: AuthClass
  getBearerToken: () => Promise<string | undefined>
}>({
  defaultAuth,
  sessionAuth,
  getBearerToken,
})

export default AuthContext
