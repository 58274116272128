import { Mutex } from 'async-mutex'
import { IDBPDatabase, openDB } from 'idb'
import { GameToPublish } from '../../GameDatastoreContext/GameDatastoreContext'
import { GameSyncingWorkerNotifications } from '../GameSyncing.types'
import {
  LocallyScoredGamesUpdateNotification,
  PublishedGamesUpdateNotification,
  SyncingWorkerNotification,
} from '../notifications/GameSyncingWorkerNotifications'
import {
  IdbLastUpdated,
  IdbLocallyScoredGame,
  IdbSessionDb,
  sessionDBName,
} from './Idb.types'
import { isPromise } from './Idb.util'

/**
 * We define this interface to be used in the main thread.
 * Keeping writes as limited to the webworker as possible will make it easier
 * to deal with conflicts and concurrency (since we can use a Mutex defined as a class variable)
 *
 * Along those lines the main thread has a chance to load slightly stale data, but the notifications/events that are emitted should
 * catch it back up.
 */
export interface IdbSessionReadonlyDatastore {
  getLocallyScoredUnpublishedGames(): Promise<IdbLocallyScoredGame[]>

  getPublishedGames(): Promise<Record<string, GameToPublish>>

  getLocallyScoredGames(): Promise<Record<string, IdbLocallyScoredGame>>

  getLocallyScoredGame(
    gameID: string,
  ): Promise<IdbLocallyScoredGame | undefined>
}

/**
 * We define this interface to be used in the main thread. It has a very limited scope which allows it to create
 * the last-updated table. It should only be used on session creation
 */
export interface IdbSessionCreationDatastore {
  updateLastUpdatedForGames(games: IdbLastUpdated[]): Promise<void>

  closeDatabase(): Promise<void>
}

export class IdbSessionDatastore
  implements IdbSessionReadonlyDatastore, IdbSessionCreationDatastore
{
  private database:
    | Promise<IDBPDatabase<IdbSessionDb>>
    | IDBPDatabase<IdbSessionDb>
    | undefined = undefined

  constructor(
    private sendNotification: (
      notification: SyncingWorkerNotification<GameSyncingWorkerNotifications>,
    ) => void,
  ) {}

  /**
   * We want to avoid the scenario where a game is added to be published (or updated) at the same time
   * we get a response from live scoring and they both try to update the respective record
   *
   * Realistically we could have a mutex per game but that's overkill given how infrequently the records need to be updated
   */
  private publishedGamesMutex: Mutex = new Mutex()

  /**
   * Get the games that have been locally scored which still need events to be sync'd to live scoring.
   * Used on page load to grab all the games and pass to the live scoring manager
   *
   * Excludes games that have been "Published"
   */
  public async getLocallyScoredUnpublishedGames(): Promise<
    IdbLocallyScoredGame[]
  > {
    const db = await this.getOrCreateDatabase()
    const [locallyScoredGames, publishedGames] = await Promise.all([
      db.getAll('locally-scored-games'),
      db.getAll('published-games'),
    ])

    const filteredGames = publishedGames
      .filter(p => p.status === 'PUBLISHED')
      .map(p => p.id)
    return locallyScoredGames.filter(
      l => !filteredGames.includes(l.id) && l.status !== 'SYNCED',
    )
  }

  /**
   * Add or update a published game, defaults to NOT_PUBLISHED
   */
  public async addOrUpdatePublishedGame(
    input: Partial<GameToPublish> & { id: string },
  ): Promise<void> {
    const db = await this.getOrCreateDatabase()

    return await this.publishedGamesMutex.runExclusive(async () => {
      const tx = db.transaction('published-games', 'readwrite')
      const existing = await tx.store.get(input.id)

      const result: GameToPublish = {
        status: 'NOT_PUBLISHED',
        ...existing,
        ...input,
      }
      await tx.store.put({ status: 'NOT_PUBLISHED', ...existing, ...input })
      await tx.done

      this.sendNotification(new PublishedGamesUpdateNotification(result))
    })
  }

  public async getPublishedGames(): Promise<Record<string, GameToPublish>> {
    const db = await this.getOrCreateDatabase()

    const tx = db.transaction('published-games', 'readwrite')
    const res = await tx.store.getAll()
    return res.reduce((acc: Record<string, GameToPublish>, val) => {
      acc[val.id] = val
      return acc
    }, {})
  }

  public async addOrUpdateLocallyScoredGame(
    input: Partial<IdbLocallyScoredGame> & { id: string },
  ): Promise<void> {
    const db = await this.getOrCreateDatabase()

    const tx = db.transaction('locally-scored-games', 'readwrite')
    const existing = await tx.store.get(input.id)
    const result = { ...existing, ...input }
    await tx.store.put(result)
    await tx.done

    this.sendNotification(new LocallyScoredGamesUpdateNotification(result))
  }

  public async getLocallyScoredGames(): Promise<
    Record<string, IdbLocallyScoredGame>
  > {
    const db = await this.getOrCreateDatabase()

    const tx = db.transaction('locally-scored-games', 'readonly')
    const res = await tx.store.getAll()

    return res.reduce((acc: Record<string, IdbLocallyScoredGame>, val) => {
      acc[val.id] = val
      return acc
    }, {})
  }

  public async getLocallyScoredGame(
    gameID: string,
  ): Promise<IdbLocallyScoredGame | undefined> {
    const db = await this.getOrCreateDatabase()
    const tx = db.transaction('locally-scored-games', 'readonly')
    return tx.store.get(gameID)
  }

  public async updateLastUpdatedForGames(
    games: IdbLastUpdated[],
  ): Promise<void> {
    const db = await this.getOrCreateDatabase()

    const tx = db.transaction('last-updated', 'readwrite')
    for (const input of games) {
      const existing = await tx.store.get(input.id)
      const result = { ...existing, ...input }
      await tx.store.put(result)
    }

    await tx.done
  }

  public async getGameLastUpdated(
    gameID: string,
  ): Promise<IdbLastUpdated | undefined> {
    const db = await this.getOrCreateDatabase()
    return await db.get('last-updated', gameID)
  }

  public async sessionCleanup() {
    if (this.database && !isPromise(this.database)) {
      await Promise.all([
        this.database.clear('published-games'),
        this.database.clear('locally-scored-games'),
        this.database.clear('last-updated'),
      ])
    }

    if (this.database) {
      const db = await this.database
      await Promise.all([
        db.clear('published-games'),
        db.clear('locally-scored-games'),
        db.clear('last-updated'),
      ])
    }

    await this.closeDatabase()
    this.database = undefined
  }

  public async closeDatabase() {
    if (this.database && !isPromise(this.database)) {
      this.database.close()
    }

    if (this.database) {
      const db = await this.database
      db.close()
    }

    this.database = undefined
  }

  private async getOrCreateDatabase(): Promise<IDBPDatabase<IdbSessionDb>> {
    /**
     * The IdbSessionDatastore is a class where one instance is used across multiple places.
     * If two come along before the databases for a game has been created, it's possible they'll both end up calling `openDB`
     * To get around that, we store the promise from openDB before it's awaited,
     * so rather than calling `openDB` twice it will just resolve the same promise.
     */
    if (this.database && !isPromise(this.database)) {
      return this.database
    }

    if (this.database) {
      return await this.database
    }

    const dbP = openDB<IdbSessionDb>(sessionDBName, 1, {
      upgrade(db) {
        db.createObjectStore('published-games', {
          keyPath: 'id',
        })

        db.createObjectStore('locally-scored-games', {
          keyPath: 'id',
        })

        db.createObjectStore('last-updated', {
          keyPath: 'id',
        })
      },
    })
    this.database = dbP

    const db = await dbP
    this.database = db
    return db
  }
}
