import { mq } from '@gameonsports/components/lib/_utils/styled-components-utils'
import Loader from '@gameonsports/components/lib/Loader'
import { Text } from '@gameonsports/components/lib/TextV3'
import React from 'react'
import styled from 'styled-components'
import { BaseButtonVariant, Button } from '../Button'
import DialogModal from '../DialogModal'

const StyledModal = styled(DialogModal)`
  display: grid;
  grid-gap: 2rem;
`

const ModalBody = styled.div`
  display: grid;
  grid-gap: 1.5rem;
`

const ButtonHolder = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;

  ${mq.up('largeMobile')} {
    grid-auto-flow: column;
    justify-content: end;
  }

  button {
    outline: none;
  }
`

const StyledLoader = styled(Loader)`
  margin: 0;
  width: 1.5rem;
  height: 1.5rem;
  svg {
    stroke: currentColor;
  }
`

interface ConfirmModalProps {
  onCancel?: () => void
  onConfirm?: () => void
  onClickOutside?: () => void
  title: string
  description?: string
  confirmButtonLabel?: string
  confirmButtonVariant?: BaseButtonVariant
  cancelButtonLabel?: string
  loading?: boolean
  showButtons?: boolean
  hasCloseButton?: boolean
}

const ConfirmModal: React.SFC<ConfirmModalProps> = ({
  onCancel,
  onClickOutside,
  onConfirm,
  title,
  description,
  confirmButtonLabel = 'Confirm',
  confirmButtonVariant = 'primary',
  cancelButtonLabel = 'Cancel',
  loading,
  children,
  showButtons = true,
  hasCloseButton,
}) => (
  <StyledModal
    onClickOutside={() => {
      if (loading) {
        return
      }
      if (onClickOutside) {
        onClickOutside()
      }
    }}
    hasCloseButton={hasCloseButton}
  >
    <ModalBody>
      <Text size="24" weight="700">
        {title}
      </Text>
      {description && <Text>{description}</Text>}
      {children}
    </ModalBody>
    {showButtons && (
      <ButtonHolder>
        {onCancel && (
          <Button
            $variant="secondary"
            onClick={() => !loading && onCancel && onCancel()}
            disabled={loading}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            data-testid="confirm-modal-cancel-button"
          >
            {cancelButtonLabel}
          </Button>
        )}
        {onConfirm && (
          <Button
            $variant={confirmButtonVariant}
            onClick={() => !loading && onConfirm()}
            data-testid="confirm-modal-confirm-button"
            disabled={loading}
          >
            {confirmButtonLabel}
            {loading && <StyledLoader size={1} />}
          </Button>
        )}
      </ButtonHolder>
    )}
  </StyledModal>
)

export default ConfirmModal
