import Loader from '@gameonsports/components/lib/Loader'
import { WindowLocation } from '@reach/router'
import React, { useContext, useEffect } from 'react'
import { useAppcuesQuery } from '../../generated/graphql'
import useOfflineAuthentication from '../../hooks/useOfflineAuthentication'
import AuthContext from '../AuthContext/AuthContext'

declare global {
  interface Window {
    Appcues: any
  }
}

interface AppcuesProps {
  location?: WindowLocation
  children?: React.ReactNode
}

const Appcues = ({ children, location }: AppcuesProps) => {
  const { defaultAuth, sessionAuth } = useContext(AuthContext)
  const { checked: defaultAuthChecked, authenticated: defaultAuthenticated } =
    useOfflineAuthentication(defaultAuth)
  const { checked: sessionAuthChecked, authenticated: sessionAuthenticated } =
    useOfflineAuthentication(sessionAuth)

  useEffect(() => {
    // Have to check if it exists as ad blockers will block it
    if (window.Appcues) {
      window.Appcues.page()
    }
  }, [location])

  const isChecked = defaultAuthChecked && sessionAuthChecked
  const isAuthenticated = defaultAuthenticated || sessionAuthenticated

  const { loading, data } = useAppcuesQuery({
    skip: !isChecked || !isAuthenticated,
    fetchPolicy: defaultAuthChecked ? 'cache-and-network' : 'cache-only',
  })

  useEffect(() => {
    if (data?.account?.profile?.id && window.Appcues) {
      window.Appcues.identify(data.account.profile?.id, {
        firstName: data.account.profile?.firstName,
        lastName: data.account.profile?.lastName,
        email: data.account.profile?.email,
        state: data.account.profile?.address?.state,
      })
    }
  }, [data])

  if (loading) {
    return <Loader />
  }

  return <>{children}</>
}

export default Appcues
