import { MetaSession, PushLogOptions, faro } from '@grafana/faro-react'

export const pushLog = (message: unknown[], options?: PushLogOptions): void => {
  if (!process.env.REACT_APP_GRAFANA_FARO_URL) {
    return
  }

  faro.api.pushLog(message, options)
}

export const setSession = (session?: MetaSession | undefined): void => {
  if (!process.env.REACT_APP_GRAFANA_FARO_URL) {
    return
  }

  faro.api.setSession(session)
}

export const resetSession = (): void => {
  if (!process.env.REACT_APP_GRAFANA_FARO_URL) {
    return
  }

  faro.api.resetSession()
}

export {
  type BaseObject,
  LogLevel,
  type PushLogOptions,
} from '@grafana/faro-react'
