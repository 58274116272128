import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from 'react'

export interface PopoverContextProps {
  popover: ReactNode
  setPopover: Dispatch<SetStateAction<ReactNode>>
}

export const PopoverContext = createContext<PopoverContextProps>({
  popover: null,
  setPopover: () => null,
})

/**
 * The purpose of the PopoverProvider component is to allow a popover to render at the root level
 * of the dom, rather than within a deeply nested component. This allows for:
 * - easier styling relative to the root
 * - assists with UX (overflow is hidden if a popover is rendered within a modal)
 */
export const PopoverProvider: React.FC = ({ children }) => {
  const [popover, setPopover] = useState<ReactNode>(null)

  return (
    <PopoverContext.Provider value={{ popover, setPopover }}>
      <>
        {children}
        {popover}
      </>
    </PopoverContext.Provider>
  )
}
