import { Location } from '@reach/router'
import React from 'react'
import ErrorPanel from '../ErrorPanel'

interface PageErrorBoundaryProps {
  href: string
}
interface State {
  error: Error | undefined
}

class PageErrorBoundary extends React.Component<PageErrorBoundaryProps, State> {
  state: State = {
    error: undefined,
  }

  componentDidCatch(error: Error) {
    if (error.message.includes('Loading chunk')) {
      this.setState({ error })
      return
    }

    // Bubble the error up
    throw error
  }

  componentDidUpdate(prevProps: PageErrorBoundaryProps) {
    // Remove error when navigating between pages
    if (prevProps.href !== this.props.href) {
      this.setState({ error: undefined })
    }
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error === undefined) {
      return children
    }

    return (
      <ErrorPanel
        title="500"
        description="Something went wrong. Please refresh this page."
        data-testid="page-load-error"
      />
    )
  }
}

const PageErrorBoundaryContainer: React.FC<{}> = ({ children }) => (
  <Location>
    {({ location }) => (
      <PageErrorBoundary href={location.href}>{children}</PageErrorBoundary>
    )}
  </Location>
)

export default PageErrorBoundaryContainer
