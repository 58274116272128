import { useLazyQuery } from '@apollo/client'
import { sessionAuth } from '../components/AuthContext'

export const useSessionLazyQuery: typeof useLazyQuery = (query, options) =>
  useLazyQuery(query, {
    ...options,
    context: {
      auth: sessionAuth,
    },
  })
