import React, { createContext, useState } from 'react'
import { v4 as uuid } from 'uuid'
import useFeatureFlagOn from '../../hooks/useFeatureFlagOn'
import { useLiveGame } from '../../hooks/useLiveGame'
import {
  GameScoringStatus,
  LiveScoringCloseCode,
  LiveScoringEventResponseTypes,
  LiveScoringState,
} from './LiveScoringProvider.types'

interface LiveScoringContextProps {
  state: {
    type: LiveScoringState
    payload?: LiveScoringEventResponseTypes
  }
  liveScoringWebSocketCloseCode?: number
  isLive: boolean
  onGameTakeover: string | undefined
  gameID: string | undefined
  transition: (
    to: LiveScoringState,
    payload?: LiveScoringEventResponseTypes,
  ) => void
  setGameID: (gameID: string | undefined) => void
}

export const LiveScoringContext = createContext<LiveScoringContextProps>({
  state: { type: LiveScoringState.Idle },
  isLive: false,
  onGameTakeover: undefined,
  gameID: undefined,
  transition: () => null,
  setGameID: (_: string | undefined) => null,
})

/**
 * This context is sticking around due to LiveScoringModals, there is a slight change that needs to be made to
 * LiveScoringModalsV2 so that it can handle when secondary scorer is OFF
 */
export const LiveScoringProvider: React.FC<{}> = ({ children }) => {
  const isSecondaryScorerOn = useFeatureFlagOn('bench-secondary-scorer')

  const [gameID, setGameID] = useState<string>()
  const [state, transition] = useState<LiveScoringContextProps['state']>({
    type: LiveScoringState.Idle,
  })

  const [isLive, setIsLive] = useState(false)
  const [onGameTakeover, setOnGameTakeover] = useState<string | undefined>(
    undefined,
  )
  const [liveScoringWebSocketCloseCode, setLiveScoringWebSocketCloseCode] =
    useState<number>()

  useLiveGame({
    gameID,
    onConnectionStateChange(e) {
      if (isSecondaryScorerOn) {
        return
      }

      setIsLive(e.connected)
    },
    onGameOwnerChange: e => {
      if (isSecondaryScorerOn) {
        return
      }
      // Map to the old way of handling game statuses, the long term plan is to remove this provider altogether
      switch (e.status) {
        case GameScoringStatus.CanScore:
          transition({ type: LiveScoringState.Scoring })
          setLiveScoringWebSocketCloseCode(undefined)
          if (e.takeover) {
            setOnGameTakeover(uuid())
          }
          break
        case GameScoringStatus.NotOwner:
          if (state.type === LiveScoringState.Scoring) {
            setLiveScoringWebSocketCloseCode(
              LiveScoringCloseCode.GameAlreadyOwned,
            )
          } else {
            transition({ type: LiveScoringState.GameAlreadyOwned })
          }
          break
        case GameScoringStatus.AdminOwner:
          if (state.type === LiveScoringState.Scoring) {
            setLiveScoringWebSocketCloseCode(
              LiveScoringCloseCode.AdminOverridden,
            )
          } else {
            transition({ type: LiveScoringState.AdminOverridden })
          }
          break
        case GameScoringStatus.InvalidStatus:
          if (state.type === LiveScoringState.Scoring) {
            setLiveScoringWebSocketCloseCode(
              LiveScoringCloseCode.GameIncorrectStatus,
            )
          } else {
            transition({ type: LiveScoringState.InvalidStatus })
          }
          break
      }
    },
  })

  return (
    <LiveScoringContext.Provider
      value={{
        state,
        liveScoringWebSocketCloseCode,
        isLive,
        onGameTakeover,
        gameID,
        transition: t => transition({ type: t }),
        setGameID: newGameID => {
          setLiveScoringWebSocketCloseCode(undefined)
          transition({ type: LiveScoringState.Idle })
          setGameID(newGameID)
          return
        },
      }}
    >
      {children}
    </LiveScoringContext.Provider>
  )
}
