import Button from '@gameonsports/components/lib/Button'
import { Text } from '@gameonsports/components/lib/TextV3'
import React from 'react'
import styled from 'styled-components'
import useInnerWidthHeight from '../../hooks/useInnerWidthHeight'
import Logo from '../Logo'

const Container = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: ${props => `${props.height}px`};
  justify-content: center;
  align-items: center;
  text-align: center;

  & > * {
    margin: 0;
    max-width: 28.5rem;
  }

  & > * + * {
    margin-top: 1.5rem;
  }

  & > *:last-child {
    margin-top: 3rem;
  }
`

const StyledLogo = styled(Logo)`
  display: block;
  width: 7rem;
  height: auto;

  /* IE 11 hack to fix the height */
  \height: 3rem;
`

const BrowserSupport = () => {
  const { height } = useInnerWidthHeight()

  return (
    <Container height={height}>
      <StyledLogo height={100} width={100} />
      <Text as="h1" weight="700" size="28">
        There's a problem with your browser
      </Text>
      <Text as="p">
        Your browser is not supported or you have private browsing switched on.
        Try updating to a modern browser or switch off private browsing.
      </Text>
      <Text as="p" weight="600">
        Visit our support page for further information
      </Text>
      <Button
        as="a"
        href="https://support.playhq.com/"
        target="_blank"
        rel="nofollow noopener"
      >
        Visit support
      </Button>
    </Container>
  )
}

export default BrowserSupport
