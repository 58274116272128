import { DocumentNode } from '@apollo/client'
import { cloneDeep } from '@apollo/client/utilities'
import { visit } from 'graphql'

function removeFields(
  query: DocumentNode,
  fieldsToRemove: readonly string[],
): DocumentNode {
  //https://graphql.org/graphql-js/language/#visit
  return visit(query, {
    // tslint:disable-next-line:function-name
    Field: {
      enter(node: any) {
        // @return
        //   undefined: no action
        //   false: skip visiting this node
        //   visitor.BREAK: stop visiting altogether
        //   null: delete this node
        //   any value: replace this node with the returned value
        return fieldsToRemove.indexOf(node.name.value) > -1 ? null : undefined
      },
    },
  })
}

/*
 * Will traverse the provided query and remove fields with the provided name
 * Useful to optionally query fields on existing types.
 */
export const excludeFields = (
  query: DocumentNode,
  fields: Array<{ key: string; include: boolean }>,
) => {
  return removeFields(
    cloneDeep(query),
    fields.filter(f => !f.include).map(f => f.key),
  )
}
