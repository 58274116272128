import isEqual from 'lodash/isEqual'
import { useRef } from 'react'

const useMemoizedEqualObject = (value: any) => {
  const ref = useRef()
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export default useMemoizedEqualObject
