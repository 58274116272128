import styled, { css } from 'styled-components'

export type BaseButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'error'

interface BaseButtonProps {
  $variant?: BaseButtonVariant
}

export const BaseButton = styled.button<BaseButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  line-height: 1;
  gap: 0.5rem;
  border: none;
  cursor: pointer;

  &:disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.4;
  }

  ${({ $variant }) =>
    (!$variant || $variant === 'primary') &&
    css`
      color: ${({ theme }) => theme.white400};
      background-color: ${({ theme }) => theme.blueberry400};
      svg {
        fill: ${({ theme }) => theme.white400};
      }
      &:hover {
        background-color: ${({ theme }) => theme.blueberry400};
      }
    `}
  ${({ $variant }) =>
    $variant === 'secondary' &&
    css`
      color: ${({ theme }) => theme.blueberry400};
      background-color: ${({ theme }) => theme.blueberry100};
      svg {
        fill: ${({ theme }) => theme.blueberry400};
      }
      &:hover {
        color: ${({ theme }) => theme.white400};
        background-color: ${({ theme }) => theme.blueberry400};
        svg {
          fill: ${({ theme }) => theme.white400};
        }
      }
    `}
  ${({ $variant }) =>
    $variant === 'tertiary' &&
    css`
      color: ${({ theme }) => theme.white400};
      background-color: ${({ theme }) => theme.watermelon400};
      &:hover {
        background-color: ${({ theme }) => theme.cherry400};
      }
    `}
  ${({ $variant }) =>
    $variant === 'error' &&
    css`
      color: ${({ theme }) => theme.white400};
      background-color: ${({ theme }) => theme.watermelon400};
      svg {
        fill: ${({ theme }) => theme.white400};
      }
      &:hover {
        background-color: ${({ theme }) => theme.watermelon400};
      }
    `}
`

export const Button = styled(BaseButton)`
  font-weight: 500;
  padding: 0 1.5rem;
  font-size: 1rem;
  border-radius: 2rem;
  height: 3rem;
`
