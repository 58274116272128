import {
  GameSyncingWorkerEventsSyncedNotification,
  GameSyncingWorkerGameResetEvent,
  GameSyncingWorkerGameUpdatedInPlaymakerEvent,
  GameSyncingWorkerLiveGameConnectionState,
  GameSyncingWorkerLiveGameOwnerNotification,
  GameSyncingWorkerLocallyScoredGamesUpdate,
  GameSyncingWorkerNotifications,
  GameSyncingWorkerPublishedGamesUpdate,
  eventsSyncedEvent,
  gameResetEvent,
  gameUpdatedInPlaymakerEvent,
  liveGameConnectionStateEvent,
  liveGameOwnerNotificationEvent,
  locallyScoredGameUpdateEvent,
  publishedGameUpdateEvent,
} from '../GameSyncing.types'

export abstract class SyncingWorkerNotification<
  T extends GameSyncingWorkerNotifications,
> {
  public abstract get message(): T

  public abstract get logContext(): Record<
    string,
    // Faro currently only allows strings here
    string | undefined
  >

  public loggable(): boolean {
    return true
  }
}

export class LiveGameOwnerNotification extends SyncingWorkerNotification<GameSyncingWorkerLiveGameOwnerNotification> {
  constructor(
    private payload: GameSyncingWorkerLiveGameOwnerNotification['payload'],
  ) {
    super()
  }

  get logContext(): Record<string, string | undefined> {
    return {
      gameID: this.payload.gameID,
      status: this.payload.status,
      currentScoringRole: this.payload.currentScoringRole,
      takeover: String(this.payload.takeover),
    }
  }

  get message(): GameSyncingWorkerLiveGameOwnerNotification {
    return {
      type: liveGameOwnerNotificationEvent,
      payload: this.payload,
    }
  }
}

export class EventsSyncedNotification extends SyncingWorkerNotification<GameSyncingWorkerEventsSyncedNotification> {
  constructor(
    private payload: GameSyncingWorkerEventsSyncedNotification['payload'],
  ) {
    super()
  }

  get logContext(): Record<string, string> {
    return {
      gameID: this.payload.gameID,
      eventLogReplaced: String(this.payload.eventLogReplaced),
      eventCount: String(this.payload.events.length),
    }
  }

  get message(): GameSyncingWorkerEventsSyncedNotification {
    return {
      type: eventsSyncedEvent,
      payload: this.payload,
    }
  }
}

export class LocallyScoredGamesUpdateNotification extends SyncingWorkerNotification<GameSyncingWorkerLocallyScoredGamesUpdate> {
  constructor(
    private payload: GameSyncingWorkerLocallyScoredGamesUpdate['payload'],
  ) {
    super()
  }

  get logContext(): Record<string, string | undefined> {
    return {
      gameID: this.payload.id,
      ...this.payload,
      lastEventReset: String(this.payload.lastEventReset),
      id: undefined,
    }
  }

  get message(): GameSyncingWorkerLocallyScoredGamesUpdate {
    return {
      type: locallyScoredGameUpdateEvent,
      payload: this.payload,
    }
  }
}

export class PublishedGamesUpdateNotification extends SyncingWorkerNotification<GameSyncingWorkerPublishedGamesUpdate> {
  constructor(
    private payload: GameSyncingWorkerPublishedGamesUpdate['payload'],
  ) {
    super()
  }

  get logContext(): Record<string, string> {
    return {
      gameID: this.payload.id,
      status: this.payload.status,
    }
  }

  get message(): GameSyncingWorkerPublishedGamesUpdate {
    return {
      type: publishedGameUpdateEvent,
      payload: this.payload,
    }
  }
}

export class LiveGameConnectionStateNotification extends SyncingWorkerNotification<GameSyncingWorkerLiveGameConnectionState> {
  constructor(
    private payload: GameSyncingWorkerLiveGameConnectionState['payload'],
    private skipLog?: boolean,
  ) {
    super()
  }

  get logContext(): Record<string, string> {
    return {
      connected: String(this.payload.connected),
    }
  }

  get message(): GameSyncingWorkerLiveGameConnectionState {
    return {
      type: liveGameConnectionStateEvent,
      payload: this.payload,
    }
  }

  public loggable(): boolean {
    return !this.skipLog
  }
}

export class GameUpdatedInPlaymakerNotification extends SyncingWorkerNotification<GameSyncingWorkerGameUpdatedInPlaymakerEvent> {
  constructor(
    private payload: GameSyncingWorkerGameUpdatedInPlaymakerEvent['payload'],
    private skipLog?: boolean,
  ) {
    super()
  }

  get logContext(): Record<string, string> {
    return this.payload
  }

  get message(): GameSyncingWorkerGameUpdatedInPlaymakerEvent {
    return {
      type: gameUpdatedInPlaymakerEvent,
      payload: this.payload,
    }
  }

  public loggable(): boolean {
    return !this.skipLog
  }
}

export class GameResetNotification extends SyncingWorkerNotification<GameSyncingWorkerGameResetEvent> {
  constructor(private payload: GameSyncingWorkerGameResetEvent['payload']) {
    super()
  }

  get logContext(): Record<string, string> {
    return this.payload
  }

  get message(): GameSyncingWorkerGameResetEvent {
    return {
      type: gameResetEvent,
      payload: this.payload,
    }
  }
}
