import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import useInnerWidthHeight from '../../hooks/useInnerWidthHeight'
import bgLarge from './background-lg.jpg'
import bgSmall from './background-sm.jpg'

// Not using props and theme in here as the ErrorBoundary wraps the whole app and wont have access to the theme
const Container = styled.div<{ height: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${props => `${props.height}px`};
  background-image: url(${bgSmall});
  background-size: cover;
  color: black;

  @media screen and (min-width: 75rem) {
    background-image: url(${bgLarge});
  }
`

const Heading = styled.h1`
  font-size: 8rem;
  margin: 0 0 1rem;
`

const P = styled.p`
  font-size: 2rem;
`

interface ErrorPageProps {
  message?: string
}

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0
  }
`

const ErrorPage: React.FC<ErrorPageProps> = () => {
  const { height } = useInnerWidthHeight()

  return (
    <Container height={height} data-testid="error-page">
      <GlobalStyles />
      <Heading>500</Heading>
      <P>Looks like we've gone out of bounds</P>
    </Container>
  )
}

export default ErrorPage
