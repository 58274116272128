import { DBSchema, IDBPDatabase } from 'idb'
import { SyncedGameEvents } from '../../../hooks/useGameDb'
import {
  GameScoringStatus,
  ScoringRoles,
} from '../../../providers/LiveScoringProvider/LiveScoringProvider.types'
import { GameToPublish } from '../../GameDatastoreContext/GameDatastoreContext'

export const sessionDBName = 'session'
const publishedGamesTableName = 'published-games'
const locallyScoredGamesTableName = 'locally-scored-games'
const lastUpdatedTableName = 'last-updated'

export interface IdbLocallyScoredGame {
  id: string
  status?: 'EVENTS_TO_SYNC' | 'SYNCED'
  scoringRole?: ScoringRoles
  // The game scoring status that was last stored for the game. Can be out of date if device goes offline.
  lastGameScoringStatus?: GameScoringStatus
  lastEventReset?: boolean
}

export interface IdbLastUpdated {
  id: string
  local?: Date
}

export interface IdbSessionDb extends DBSchema {
  [publishedGamesTableName]: {
    key: string
    value: GameToPublish
  }
  [locallyScoredGamesTableName]: {
    key: string
    value: IdbLocallyScoredGame
  }
  [lastUpdatedTableName]: {
    key: string
    value: IdbLastUpdated
  }
}

export const eventsTableName = 'events'

export interface IdbGameDbSchema extends DBSchema {
  [eventsTableName]: {
    key: string
    value: SyncedGameEvents
    indexes: { timestamp: number }
  }
}

export type IdbGameDb = IDBPDatabase<IdbGameDbSchema>
