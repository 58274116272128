import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth'
import { useEffect, useState } from 'react'

// We have to check if the session user exists in the cache only. This is so that if the app is offline,
// we can validate if they are logged in as a session user without refreshing their access token via
// Cognito, which results in an error as there is no network capability when the app is offline.

interface OfflineAuth {
  checked: boolean
  authenticated: boolean | null
}

const useOfflineAuthentication = (auth: AuthClass) => {
  const [state, setState] = useState<OfflineAuth>({
    checked: false,
    // Start as `null` to make sure we know when it explicitly gets set as a boolean
    authenticated: null,
  })

  useEffect(() => {
    setState({
      checked: true,
      // @ts-ignore
      authenticated: !!auth.userPool?.getCurrentUser(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return state
}

export default useOfflineAuthentication
